// @ts-strict-ignore
import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import type { ChartOptions } from "chart.js";
import { DefaultTypes } from "src/app/shared/service/defaulttypes";
import { ChartAxis, YAxisType } from "src/app/shared/service/utils";

import { QueryHistoricTimeseriesDataResponse } from "../../../shared/jsonrpc/response/queryHistoricTimeseriesDataResponse";
import { ChannelAddress, Edge, EdgeConfig, Service } from "../../../shared/shared";
import { AbstractHistoryChart } from "../abstracthistorychart";

@Component({
  selector: "heatingelementChart",
  templateUrl: "../abstracthistorychart.html",
})
export class HeatingelementChartComponent extends AbstractHistoryChart implements OnInit, OnChanges, OnDestroy {

  @Input({ required: true }) public period!: DefaultTypes.HistoryPeriod;
  @Input({ required: true }) public component!: EdgeConfig.Component;

  constructor(
    protected override service: Service,
    protected override translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    super("heatingelement-chart", service, translate);
  }

  ngOnChanges() {
    this.updateChart();
  }

  ngOnInit() {
    this.startSpinner();
    this.setLabel();
  }

  ngOnDestroy() {
    this.unsubscribeChartRefresh();
  }

  public getChartHeight(): number {
    return window.innerHeight / 1.3;
  }

  protected updateChart() {
    this.autoSubscribeChartRefresh();
    this.startSpinner();
    this.colors = [];
    this.loading = true;
    this.queryHistoricTimeseriesData(this.period.from, this.period.to).then(response => {
      this.service.getCurrentEdge().then(() => {
        const result = (response as QueryHistoricTimeseriesDataResponse).result;
        // convert labels
        const labels: Date[] = [];
        for (const timestamp of result.timestamps) {
          labels.push(new Date(timestamp));
        }
        this.labels = labels;

        // convert datasets
        const datasets = [];
        const level = this.component.id + "/Level";

        if (level in result.data) {
          const levelData = result.data[level].map(value => {
            if (value == null) {
              return null;
            } else {
              return value;
            }
          });
          datasets.push({
            label: "Level",
            data: levelData,
          });
          this.colors.push({
            backgroundColor: "rgba(200,0,0,0.05)",
            borderColor: "rgba(200,0,0,1)",
          });
        }
        this.datasets = datasets;
        this.loading = false;
        this.stopSpinner();

      }).catch(reason => {
        console.error(reason); // TODO error message
        this.initializeChart();
        return;
      });

    }).catch(reason => {
      console.error(reason); // TODO error message
      this.initializeChart();
      return;
    }).finally(async () => {
      this.formatNumber = "1.0-1";
      this.unit = YAxisType.NONE;
      await this.setOptions(this.options);
      this.applyControllerSpecificOptions(this.options);
    });
  }

  protected getChannelAddresses(edge: Edge, config: EdgeConfig): Promise<ChannelAddress[]> {
    return new Promise((resolve) => {
      const levels = new ChannelAddress(this.component.id, "Level");
      const channeladdresses = [levels];
      resolve(channeladdresses);
    });
  }

  protected applyControllerSpecificOptions(options: ChartOptions) {
    options.scales[ChartAxis.LEFT]["title"].text = "Level";
    options.scales[ChartAxis.LEFT]["beginAtZero"] = true;
    options.scales[ChartAxis.LEFT].max = 3;
    options.scales[ChartAxis.LEFT].ticks["stepSize"] = 1;
    this.options = options;
  }

  protected setLabel() {
    this.options = this.createDefaultChartOptions();
  }

}
